import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css } from '@emotion/css';
import { useStyles2, Button, useTheme2 } from '@grafana/ui';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import DropdownCheckboxForm from './DropdownCheckboxForm';

interface Props extends PanelProps<SimpleOptions> {}



const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    input: css`
      position: relative;
      width: 80%;

    `,
    button: css`
      position: relative;
      padding: 15px;
      margin-left: 2%;
    `,
    li: css`
      display: flex;
      margin-bottom: 5px;
    ` ,
    deleteButton: css`
    display: inline-block;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  `,

  };
};
/*
interface Error {
  id: number;
  parameter: string;
  message: string;
}
*/

interface Ierror {
  id: number;
  message: string;
  show: boolean;
}
type ReportSettings={
  reportRate: string;
  active: boolean;
};
interface ReportTable{
id: number;
email: string;
settings: ReportSettings;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  //console.log(data.request?.targets[0].datasource?.uid);
  const styles = useStyles2(getStyles);
  const theme = useTheme2();
  //const [errors, setErrors] = useState<Error[]>([]);

  //The first value,  is our current state.The second value is the function that is used to update our state.

  const [reports,setReports] = useState<ReportTable[]>([]);
  
  const [formError, setFormError] = useState<Ierror[]>([]);

  const [email, setEmail] = useState('');
  const [update,setUpdate] = useState(false);
 // Function to handle setSelectedShift


  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource: {
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  
  //1. fetch data
  const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier

  //table select for vemat_devices 
  const sqlCommands = `SELECT id,email,settings FROM email_reporting order by id asc`;
 

  const select_table = createQuery('now-1h', 'now', datasourceUID, sqlCommands);
  const handleActiveChange = (index: number,id: number)=>{
    const _reports = [...reports];
    _reports[index].settings.active=!_reports[index].settings.active;
    setReports(_reports);
    
//update active
const updateSql = `UPDATE email_reporting SET settings = '${JSON.stringify(_reports[index].settings)}' WHERE id = ${_reports[index].id}`;
const updateQuery = createQuery('now-1h', 'now', datasourceUID, updateSql);
  console.log(updateSql);
  fetchData(grafanaApiBaseUrl, method, headers, updateQuery);
};

const headers = useMemo(() => {
  // Replace the following with your actual header initialization logic
  return {
    //Authorization: 'Bearer your_access_token',
    'Content-Type': 'application/json',
  };
}, []);
  //2. onclick event handler
  //3. edit / delete
  const handleFormSubmit = async(
      email: string
      ) => {
    
     if (!email.trim()) {
       alert('Wrong e-mail.');
       return;
     }

     const toS: ReportSettings = {
      
      reportRate: "weekly",
      active: false
     };
    
    const sqlInsert = `INSERT INTO email_reporting(email, settings) VALUES ('${email}', '${JSON.stringify(toS)}')`;
     console.log(sqlInsert);
    const updateQuery = createQuery('now-1h', 'now', datasourceUID, sqlInsert);
   await fetchData(grafanaApiBaseUrl, method, headers, updateQuery);
    setUpdate(!update);
     // Reset form fields
     setEmail('');
     setReports([]);
   };

  const reloadTable = useCallback(() => {
    
  
    fetchData(grafanaApiBaseUrl, method, headers, select_table).then(data => {
      if (data) {
        setReports([]);
        const ids = data.results.A.frames[0].data.values[0];
        const emails = data.results.A.frames[0].data.values[1];
        const settings = data.results.A.frames[0].data.values[2];
  
        const mappedData = ids.map((id: number, index: number) => ({
          id,
          email: emails[index],
          settings: JSON.parse(settings[index]),
          showError: false,
        }));
  
        //map error array
  
        const errList: Ierror[] = [];
        for (let i = 0; i < mappedData.length; i++) {
          errList.push({ id: mappedData[i].id, message: '', show: false });
        }
        setFormError(errList);
  
        setReports(mappedData);
        //napamovani nactenych dat do StateVariable
      }
    });
  }, [grafanaApiBaseUrl, method, headers, select_table, setReports, setFormError]);
  
  //data fetching and processing
  useEffect(() => {
    reloadTable();
    return () => {
      // Cleanup code here
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

const handleChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  id: number,
  field: string
) => {
  const newData = reports.map((device, index) =>
    index === id ? { ...device, settings: { ...device.settings, [field]: event.target.value } } : device
  );
  setReports(newData);

  // Clear the error for the specific field
  const newErrors = formError.map((error, index) =>
    index === id ? { ...error, show: false, message: '' } : error
  );
  setFormError(newErrors);
};

  const handleDelete = (index: number) => {
   

    const deleteSql = `DELETE FROM email_reporting WHERE id = ${reports[index].id}`;
    const deleteQuery = createQuery('now-1h', 'now', datasourceUID, deleteSql);
    fetchData(grafanaApiBaseUrl, method, headers, deleteQuery);
    
    const updated = [...reports];
     updated.splice(index, 1);
     setReports(updated);
   // setErrors(updatedErrors);

  };
/*
  const handleAdd = () => {
    //console.log(errors[0].parameter);
    const variable = replaceVariables(options.variable);
    console.log("variable", variable);
    const insertSql = `INSERT INTO errors (param, error) VALUES ('${variable}', '${newErrorMessage}');`;
    console.log("insertSQL", insertSql);
    const insertQuery = createQuery('now-1h', 'now', datasourceUID, insertSql);
    console.log(insertSql);

    fetchData(grafanaApiBaseUrl, method, headers, insertQuery);
    
    
    console.log(variable);
    setErrors([...errors, { id: errors.length + 1, parameter: options.variable, message: newErrorMessage }]);
    setNewErrorMessage('');
  };
  */
  //funkce add -> jen nektere tabulky btw

  const table_theme = createTheme({
    overrides: {
      MuiFormControl:{
      root:{
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.colors.primary.contrastText
      }
      },
      MuiInputBase:{
        input:{
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText
        }
      },
      MuiTableCell: {
        body:{backgroundColor: theme.colors.background.secondary,
          borderBottom: "0px solid rgba(224, 224, 224, 1)",
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText},
        head:{backgroundColor: theme.colors.background.secondary,
          borderBottom: "0px solid rgba(224, 224, 224, 1)",
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText},
        root: {
           backgroundColor: theme.colors.background.secondary,
           borderBottom: "0px solid rgba(224, 224, 224, 1)",
           fontFamily: theme.typography.fontFamily,
           fontSize: theme.typography.fontSize,
           color: theme.colors.primary.text
         }
       },
     },
  });

   //todo předělat alerty na zobrazení in

   //mapování do listu optionu a.k.a nastav jméno a values to select listu (takový ten dropdown dolů)


  
  return (

<ThemeProvider theme={table_theme}>
<DropdownCheckboxForm

email={email}
setEmail={setEmail}
onSubmit={handleFormSubmit}
styles={styles}
/>
    <TableContainer component={Paper} style={{maxHeight: 880,backgroundColor: theme.colors.background.primary}}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Aktivní</TableCell>
          
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reports.map((row, index) => (
          <TableRow key={index}>
                   <TableCell>
              <TextField
                value={row.email}
                onChange={(e) => handleChange(e, index, 'name')}
                error={Boolean(formError[index].show)}
                helperText={formError[index].message}
              />
            </TableCell>

            
           

            
     
           
            <TableCell>
                 <Button onClick={() => handleActiveChange(index,row.id)} className={row.settings.active?styles.button : styles.deleteButton}>{row.settings.active?"Ano":"Ne"}</Button>
            </TableCell>
            <TableCell>
                 <Button onClick={() => handleDelete(index)} className={styles.deleteButton}>Smazat</Button>
            </TableCell>

          </TableRow>
          
        ))}
      </TableBody>
    </Table>
   
    <div>
  <div>
</div>


</div>
  </TableContainer>
  </ThemeProvider>
  );
};
