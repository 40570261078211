import React from 'react';

import { Button, Form, Input } from '@grafana/ui';


interface DropdownCheckboxFormProps {
  email: string;
  setEmail: (email: string) => void;
  onSubmit: (
    email: string
  ) => void;
  styles: any; // Replace 'any' with the actual type of 'styles'
}

const DropdownCheckboxForm: React.FC<DropdownCheckboxFormProps> = ({
  email,
setEmail,
  onSubmit,
  styles,
}) => {
  const handleSubmit = (e: React.FormEvent) => {
    //e.preventDefault();
    //const shiftValue = selectedShift ? selectedShift.value : 'ranni';
    
    onSubmit(email);
    // Reset form fields
 
    setEmail('');
  };


  return (
    <div>
      <Form 
      onSubmit={handleSubmit}
      >
      {({ register, errors }) => (
          <>
        <Input
          type="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder="Email"
        />

        <Button className={styles.button} type="submit" >Přidat</Button>
        </>
        )}
      </Form>
      
    </div>
  );
};

export default DropdownCheckboxForm;
